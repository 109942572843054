import React from "react";
import '../../App.scss';
import Footer from "../../Component/Footer/Footer";
import Header from "../../Component/Header/Header";
import HeroSection from "../../Component/HeroSection/HeroSection";
import Models from "../../Component/Models/Models";
import OurApproachSec from "../../Component/OurApproachSec/OurApproachSec";
import Services from "../../Component/Services/Services";
import Technologies from "../../Component/Technologies/Technologies";
import Tools from "../../Component/Tools/Tools";
import Triodec from "../../Component/Triodec/Triodec";
import Vision from "../../Component/Vision/Vision";

function Home() {
    return (
        <div>
            <Header />
            <HeroSection />
            <Services />
            <Triodec />
            <Technologies />
            <Tools />
            <Vision />
            <OurApproachSec />
            <Models />
            <Footer />
        </div>
    )
}

export default Home