import React from "react"; 
import { Images } from "../../Config/images";
import "./HeroSection.scss";

function HeroSection() { 
  const onButtonClick = () => { 
    document.getElementById("footer").scrollIntoView({ behavior: "smooth" });
  };
  return (
    <section className="hero-section">
      <div className="container">
        <div className="hero-section__inner">
          <div className="hero-left">
            <h1>
            Turn Idea Into Opportunity, Experience {" "}
              <span className="highlights">
              IT With Us!{" "}
                <img
                  className="underline"
                  src={Images.heroTextUnderline}
                  alt="Digital World"
                />
              </span>
            </h1>
            <p>
              With the help of Innovation and Technology, We give you the power
              to change the world with your ideas.
            </p>
            <button type="button"  onClick={onButtonClick} className="btn btn-primary">
              Schedule a Meeting
            </button>
          </div>
          <div className="hero-right">
            <img
              className="hero-bg-pattern"
              src={Images.heroBgPattern}
              alt="Hero Bg"
            />
            <img
              src={Images.heroThumb}
              alt="Engineering the Next Best Thing for Digital World"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
