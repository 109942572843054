import React, { useEffect } from "react";
import { Images } from "../../Config/images";
import "../Models/Models.scss";
import ModelsCard from "../ModelsCard/ModelsCard";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function Models() {
  const onButtonClick = () => {
    document.getElementById("footer").scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    gsap.utils.toArray(".modal-card-animation").forEach((li: HTMLLIElement) => {
      gsap.fromTo(
        li,
        {
          opacity: 0,
          x: 200,
        },
        {
          ease: "none",
          opacity: 1,
          x: 0,
          scrollTrigger: {
            trigger: li,
            scrub: true,
            start: "top bottom",
            end: "+=350",
          },
        }
      );
    });

    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 300);
  }, []);
  return (
    <section className="hmModels">
      <div className="container">
        <div className="pageTitle">
          <h2>Our Models</h2>
          <p>
          We design our business model in a manner that you can choose one which suits you the most!
          </p>
        </div>
        <div className="modelsInner">
          <div className="modelsInnerCrad">
            <ModelsCard
              className="modal-card-animation"
              dedicatedIcon={Images.dedicatedIcon}
              bgColors="#E6F7F7"
              title="Dedicated Model"
              description="The Dedicated Team is a model where the client can control the entire development team remotely. It is cost-effective as the entire team is hired remotely and does not require the investment in the infrastructure still they have control and coordination with the team. The best part is the client hires them on contract only after all the due diligence and can relieve them upon completion of the project or modules. "
            />

            <ModelsCard
              className="modal-card-animation"
              dedicatedIcon={Images.demandIcon}
              bgColors="#E7E6F7"
              title="On-demand Model"
              description="We welcome the obscure vision through Time and Material Model that supports the Agile Development Process. This is a model where the client only pays for the time and resources spent on the project."
            />

            <ModelsCard
              className="modal-card-animation"
              dedicatedIcon={Images.priceIcon}
              bgColors="#F6E6E6"
              title="Fixed Price Model"
              description="We welcome the crystal clear vision of our clients and provide the best web and mobile app solutions through Fix Scope Model. The fixed Scope Model is the best model to get the complete solution under a fixed budget. "
            />
          </div>
          <div className="modelsConsultation modal-card-animation">
            <div className="modelsConsultationContent">
              <h2>Got an app idea? <br/>Let’s Chat </h2>
              <p>
              If you have any app development ideas and want to speak with someone about them! Then please feel free to contact us whenever. We are pleased to provide you with appropriate direction and assistance.
              </p>
              <button type="button" onClick={onButtonClick} className="btn btn-primary">
              Let’s Work Together!
              </button>
            </div>
            <div className="modelsConsultationImg">
              <img src={require('../../Images/Group 155.png')} alt="Images" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Models;
