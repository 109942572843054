export const Images = {
    logo: require('../Images/logo.svg').default,
    ServicesIcon: require('./../Images/services-icon.svg').default,
    TriodecImg: require('./../Images/triodec-img.svg').default,
    heroThumb: require('./../Images/hero-thumb.svg').default,
    heroTextUnderline: require('./../Images/hero-text-underline.svg').default,
    heroBgPattern: require('./../Images/hero-bg-pattern.svg').default,
    menuIcon: require('./../Images/menu-icon.svg').default,
    VisionImg: require('./../Images/vision-img.jpg'),
    VisionCurveImg: require('./../Images/curve.svg').default,
    MenCloseIcon: require('./../Images/menu-close-icon.svg').default,
    FrontendIcon: require('./../Images/frontend-icon.png'), 
    DatabaseIcon: require('./../Images/database-icon.png'),
    AppleIcon: require('./../Images/apple-icon.png'),
    AngularIcon: require('./../Images/angular-icon.svg').default,
    AsanaIcon: require('./../Images/asana-icon.svg').default,
    GithubIcon: require('./../Images/github-icon.svg').default,
    VisualStudioIcon: require('./../Images/visual-studio-icon.svg').default,
    JiraIcon: require('./../Images/jira-icon.svg').default,
    FigmaIcon: require('./../Images/figma-icon.svg').default,
    SkypeIcon: require('./../Images/skype-icon.svg').default,
    FacebookIcon: require('./../Images/facebook-icon.svg').default,
    TwitterIcon: require('./../Images/twitter-icon.svg').default,
    InstagramIcon: require('./../Images/instagram-icon.svg').default,
    LinkedinIcon: require('./../Images/linkedin-icon.svg').default,
    MailIcon: require('./../Images/email-icon.png'),
    SendIcon: require('./../Images/send-icon.svg').default,
    approachLeftArrow: require('./../Images/approach-left-arrow.svg').default,
    approachRightArrow: require('./../Images/approach-right-arrow.svg').default, 
    approachLogo: require('./../Images/approach-logo.svg').default,
    ModelsImg: require('./../Images/models-right-img.svg').default,
    apPlanningIcon: require('./../Images/ap-planning-icon.svg').default,
    apDesignIcon: require('./../Images/ap-design-icon.svg').default,
    apDevIcon: require('./../Images/ap-dev-icon.svg').default,
    apDeploymentIcon: require('./../Images/ap-deployment-icon.svg').default,
    apTestingIcon: require('./../Images/ap-testing-icon.svg').default,
    apDesignTwoIcon: require('./../Images/ap-design-two-icon.svg').default,
    dedicatedIcon: require('./../Images/dedicated-model.svg').default,
    demandIcon: require('./../Images/demand-icon.svg').default,
    priceIcon: require('./../Images/price-model-icon.svg').default,
    seleniumLogo: require('./../Images/selenium-logo.svg').default,
    testrailLogo: require('./../Images/testrail-logo.svg').default,
    postmanLogo: require('./../Images/postman-logo.svg').default,
    androidStudioLogo: require('./../Images/android-studio-logo.svg').default,
    bitbucketLogo: require('./../Images/bitbucket-logo.svg').default,
    teamsLogo: require('./../Images/teams-logo.svg').default,
    pychamLogo: require('./../Images/pycham-logo.svg').default,
    slackLogo: require('./../Images/slack-logo.svg').default,
    zohoLogo: require('./../Images/zoho-logo.svg').default,
    xcodeLogo: require('./../Images/xcode-logo.png'),
    invisionLogo: require('./../Images/invision-logo.svg').default,
    AiLogo: require('./../Images/ai-logo.svg').default,
    pythonLogo: require('./../Images/python-logo.svg').default,
    rubyonrailsLogo: require('./../Images/rubyonrails-logo.svg').default,
    djangoLogo: require('./../Images/django-logo.svg').default,
    flaskLogo: require('./../Images/flask-logo.svg').default,
    fastapiLogo: require('./../Images/fastapi-logo.svg').default,
    nodeJsLogo: require('./../Images/node-js-logo.svg').default,
    phpLogo: require('./../Images/php-logo.svg').default,
    dotNetLogo: require('./../Images/dot-net-logo.svg').default,
    javaLogo: require('./../Images/java-logo.svg').default,
    ReactIcon: require('./../Images/react-logo.svg').default,
    androidLogo: require('./../Images/android-logo.svg').default,
    iosLogo: require('./../Images/apple-logo.svg').default,
    vueJsLogo: require('./../Images/vue-js-logo.svg').default,
    reactNativeLogo: require('./../Images/react-native-logo.svg').default,
    flutterLogo: require('./../Images/flutter-logo.svg').default,
    sqlServerLogo: require('./../Images/sql-server-logo.svg').default,
    postgreSqlLogo: require('./../Images/postgre-sql-logo.svg').default,
    elasticSearchLogo: require('./../Images/elastic-search-logo.svg').default,
    mySqlLogo: require('./../Images/my-sql-logo.svg').default,
    mongoDBLogo: require('./../Images/mongo-db-logo.svg').default,
    cassandraLogo: require('./../Images/cassandra-logo.svg').default,
    awsLogo: require('./../Images/aws-logo.svg').default,
    herokuLogo: require('./../Images/heroku-logo.svg').default,
    digitalOceanLogo: require('./../Images/digital-ocean-logo.svg').default,
    azureLogo: require('./../Images/azure-logo.svg').default,
    hatchboxLogo: require('./../Images/hatchbox-logo.svg').default,
    googleCloudLogo: require('./../Images/google-cloud-logo.svg').default,
    mochaLogo: require('./../Images/mocha-logo.svg').default,
    jMeterLogo: require('./../Images/j-meter-logo.svg').default,
    soapuiLogo: require('./../Images/soapui-logo.svg').default,
    ketlonStudioLogo: require('./../Images/ketlon-studio-logo.svg').default,
    appiumLogo: require('./../Images/appium-logo.svg').default,
    sikuliLogo: require('./../Images/sikuli-logo.png'),
    devopsCiCd: require('./../Images/devops-ci-cd.svg').default,
    devopsJenkinsLogo: require('./../Images/devops-jenkins-logo.svg').default,
    devopsGradleLogo: require('./../Images/devops-gradle-logo.svg').default,
    circleciLogo: require('./../Images/circleci-logo.svg').default,
    awsCodepipelineLogo: require('./../Images/aws-codepipeline-lgo.svg').default,
    hasuraLogo: require('./../Images/hasura-logo.svg').default,
    
}
    
