import React from "react";
import '../TriodecTeg/TriodecTeg.scss';


function TriodecTeg(props) {
    return (
        <div className="triodecTeg">
            <span style={{backgroundColor: props.bgColors}}></span>
            {props.title}
        </div>
        
    );
};

export default TriodecTeg;