import React, { useEffect } from "react";
import { Images } from "../../Config/images";
import '../Vision/Vision.scss';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"; 
gsap.registerPlugin(ScrollTrigger); 

function Vision() {
  const onButtonClick = () => { 
    document.getElementById("footer").scrollIntoView({ behavior: "smooth" });
  };

    useEffect(() => {
        gsap.utils.toArray(".visionCard").forEach((li: HTMLLIElement) => {
          gsap.fromTo(
            li,
            {
              opacity: 0,
              x: 400,
            },
            {
              ease: "power3",
              opacity: 1,
              x: 0,
              scrollTrigger: {
                trigger: li,
                scrub: true,
                start: "top bottom",
                end: "+=650",
              },
            }
          );
        });
    
        setTimeout(() => {
          ScrollTrigger.refresh();
        }, 300);
      }, []);
    return (
        <section className="hmVision">
            <div className="visionImg">
                <img src={Images.VisionImg} alt="Images"/>     
            </div>
            <div className="visionInner">
                <div className="container">
                    <div className="visionCard">
                        <div className="visionContent">
                            <h2>Our Vision</h2>
                            <p>Our main aim is to develop in a constant manner and become a leading tech partner for every businesses. We want every business to go digital and we are happy if we become a part of their journey. Our Vision is to constantly add value to our society with the help of technology and innovation! Let’s make everything easy for everyone with IT. We want every business to reach a wider range of audience so that they can get better reach and be more successful through an online platform of their businesses.</p>
                            <button type="button" onClick={onButtonClick} className="btn btn-primary">Get in touch</button>
                            <div className="curveImg">
                                <img src={Images.VisionCurveImg} alt="Images"/>     
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Vision;