 
import React, { useEffect } from "react";
import ServicesCard from "../ServicesCard/ServicesCard";
import '../Services/Services.scss'; 
import WebEngineeringIcon from "../Icon/WebEngineeringIcon";
import DigitalEngineeringIcon from "../Icon/DigitalEngineeringIcon";
import PerformanceTestingIcon from "../Icon/PerformanceTestingIcon";
import DomainConsultantIcon from "../Icon/DomainConsultantIcon";
import WebEngineeringTwo from "../Icon/WebEngineeringTwo";
import DevOpsIcon from "../Icon/DevOpsIcon";
import UIUXDesignIcon from "../Icon/UIUXDesignIcon";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"; 
gsap.registerPlugin(ScrollTrigger); 

function Services() { 
    const onButtonClick = () => { 
        document.getElementById("footer").scrollIntoView({ behavior: "smooth" });
      };
  
    useEffect(() => { 
        if(window.innerWidth > 991) { 
      const itemsHeight = document.querySelector(".servicesWrap").clientHeight

      let items = gsap.utils.toArray(".animationCard");  
    
      gsap.to(".animationCard", {
          y: -itemsHeight + 550,
          // x: innerWidth,
          ease: "none",
          scrollTrigger: {
            trigger: ".hmServices",
            start: "top top",
            end: itemsHeight - 200,
            scrub: 1,
            pin: true,
            pinSpacing: true, 
            snap: {
                snapTo: 1 / (items.length - 2),
                duration: 1,
                delay : 0,
            }
          }
        });

        items.forEach((section) => {
          gsap.from(section, {
              scrollTrigger: {
                  start: 'top 50%',
                  end: 'bottom 50%',
                  trigger: section, 
              }
          });
      });
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 300);
    }
    }, []);
   
    return (
        <section id="serviceSec" className="hmServices">
            <div className="container">
                <div className="servicesInner">
                    <div className="pageTitle">
                        <h2>What We Offer? </h2>
                        <p>Our strength lies in the services we offer! These are the expertise in which we strongly believe that we can 
                            provide better service to our clients. </p>
                        <button type="button" onClick={onButtonClick} className="btn btn-primary">Get in touch</button>
                    </div>
                    <div className="servicesWrap">
                        <div className="servicesListLeft">
                            <ServicesCard className='animationCard' icon={<WebEngineeringIcon/>} title="Website Engineering" info="We believe in an end-to-end website design solution. Our web applications help businesses move forward by taking up their business online with full-fledged web applications. Our Website Development team offers all types of web-based software solutions and ensures a great web user experience. "/>
                            <ServicesCard className='animationCard' icon={<DigitalEngineeringIcon />} title="Digital Engineering" info="Digital transformation of your ideas is what we value at Triodec. We use advanced technologies for data capture and to design the whole paradigm. We work on projects using data and technologies such as AR/VR, Artificial Intelligence, Cloud Technology, etc. Be an early bird and integrate this advanced digital engineering into your application with us. "/>
                            <ServicesCard className='animationCard' icon={<PerformanceTestingIcon/>} title="Testing & Performance Engineering" info="We launch our software after going through many testing checkpoints of performance engineering. We believe in providing a top-notch solution to our clients. Our team makes sure that the application goes through all testing and performance parameters to optimize the performance of an application/software. "/>
                            <ServicesCard className='animationCard' icon={<DomainConsultantIcon/>} title="Consultancy & Advisory" info="Our industry experts will always be there to guide you and will answer all your queries. We have visionary business analysts with us who can help you get an idea about the market and business opportunities for the software development of your business. "/>
                        </div>
                        <div className="servicesListRight">
                            <ServicesCard className='animationCard' icon={<WebEngineeringTwo/>} title="Mobile App Engineering" info="We are an experienced team of full-stack mobile app developers. Our team will take care of the entire lifecycle of the development process starting from planning to testing the end product. Triodec understands that mobile app development means extensive reach and better visibility for your business."/>
                            <ServicesCard className='animationCard' icon={<UIUXDesignIcon/>} title="Design Engineering" info="Our team has UI UX experts who will help you to provide the best user experience to your customers followed by the latest design trends and technologies. Experience IT with the blending of attractive yet simple UI UX design that leaves a significant impact on app users. "/>
                            <ServicesCard className='animationCard' icon={<DevOpsIcon/>} title="DevOps Engineering" info="Our DevOps team will balance needs and methodologies throughout the development process. Our team will cover everything in the whole development lifecycle starting from planning to deployment of an application. DevOps team will always be there to assist our development team and our clients throughout the process. "/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;