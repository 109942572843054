import React from "react";

function WebEngineeringIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="76"
    height="76"
    fill="none"
    viewBox="0 0 76 76"
  >
    <rect width="76" height="76" fill="#79BAEC" rx="20"></rect>
    <g
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      clipPath="url(#clip0_1108_7979)"
    >
      <path d="M52.583 21.333H23.416c-1.15 0-2.083.933-2.083 2.084v29.166c0 1.15.933 2.084 2.083 2.084h29.167c1.15 0 2.083-.933 2.083-2.084V23.417c0-1.15-.932-2.084-2.083-2.084zM21.333 29.667h33.333M29.667 21.333v8.334"></path>
    </g>
    <defs>
      <clipPath id="clip0_1108_7979">
        <path
          fill="#fff"
          d="M0 0H50V50H0z"
          transform="translate(13 13)"
        ></path>
      </clipPath>
    </defs>
  </svg>
  );
}

export default WebEngineeringIcon;