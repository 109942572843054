import React from "react";
import '../ServicesCard/ServicesCard.scss';

function ServicesCard(props) {
    return (
        <div className={`servicesCard ${props.className}`}>
            <div className="servicesCardItem">
                <div className="servicesCardIcon">
                   {props.icon}
                </div>
                <div className="servicesCardContent">
                    <h3>{props.title}</h3>
                    <p>{props.info}</p>
                </div>
            </div>   
        </div>
    );
};

export default ServicesCard;