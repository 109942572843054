import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Images } from "../../Config/images";
import "./Header.scss";

function Header() { 
  const wrapperRef = useRef(null);
  const buttonRef = useRef(null);
  const [stickyClass, setStickyClass] = useState("relative");
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      window.removeEventListener("scroll", stickNavbar);
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);
  const onButtonClick = () => { 
    document.getElementById("footer").scrollIntoView({ behavior: "smooth" });
  };
  const ServiceLink = () => { 
    document.getElementById("serviceSec").scrollIntoView({ behavior: "smooth" });
    setOpenMenu(false);
  };
  const AboutUsLink = () => { 
    document.getElementById("abousUsSec").scrollIntoView({ behavior: "smooth" });
    setOpenMenu(false);
  };

  const handleClickOutside = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setOpenMenu(false);
    }
  };

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 20 ? setStickyClass("header-sticky") : setStickyClass(" ");
    }
  };
  const OpenMenuClick = () => {
    setOpenMenu(true);
  };
  const CloseMenuClick = () => {
    setOpenMenu(false);
  };
  return (
    <header className={`header ${stickyClass}`}>
      <div className="container">
        <div className="header__inner">
          <div className="header__inner_logo">
            <Link to="/">
              <img src={Images.logo} alt="Logo" />
            </Link>
          </div>
          <div
            ref={wrapperRef}
            className={`header__inner_navbar ${openMenu ? "open" : ""}`}
          >
            <div className="close-btn-row">
              <Link to="/" className="menu-logo">
                <img src={Images.logo} alt="Logo" />
              </Link>
              <button
                type="button"
                onClick={CloseMenuClick}
                className="close-btn"
              >
                <img src={Images.MenCloseIcon} alt="Menu" />
              </button>
            </div>
            <ul>
              <li>
                <span className="link" onClick={AboutUsLink}>About Us</span>
              </li>
              {/*<li>
                 <Link to="/" className="link">Career</Link>
              </li>*/}
              <li>
              <span className="link" onClick={ServiceLink}>Services</span>
              </li>
              {/*<li>
                 <Link to="/" className="link">Blogs</Link> 
              </li>*/}
            </ul>
          </div>
          <div className="header__inner_cta">
            <button type="button" onClick={onButtonClick} className="btn btn-primary">
              Contact Us
            </button>
          </div>
          <button
            ref={buttonRef}
            type="buttton"
            onClick={OpenMenuClick}
            className="menu-icon"
          >
            <img src={Images.menuIcon} alt="Menu" />
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
