import React from "react";

function DevOpsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="76"
      fill="none"
      viewBox="0 0 76 76"
    >
      <rect width="76" height="76" fill="#A783CA" rx="20"></rect>
      <g
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        clipPath="url(#clip0_1108_8015)"
      >
        <path d="M50.5 21.333h-25a6.25 6.25 0 00-6.25 6.25v4.167A6.25 6.25 0 0025.5 38h25a6.25 6.25 0 006.25-6.25v-4.167a6.25 6.25 0 00-6.25-6.25zM38 54.667H25.5a6.25 6.25 0 01-6.25-6.25V44.25A6.25 6.25 0 0125.5 38h21.875M50.502 54.667a4.167 4.167 0 100-8.334 4.167 4.167 0 000 8.334zM50.502 43.208v3.125M50.502 54.667v3.125M56.817 46.854l-2.707 1.563M46.896 52.583l-2.709 1.563M44.188 46.854l2.708 1.563M54.11 52.583l2.709 1.563M27.583 29.667v.02M27.583 46.333v.021"></path>
      </g>
      <defs>
        <clipPath id="clip0_1108_8015">
          <path
            fill="#fff"
            d="M0 0H50V50H0z"
            transform="translate(13 13)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default DevOpsIcon;