import React, { useEffect } from "react";
import { Images } from "../../Config/images";
import "../Tools/Tools.scss";
import ToolsCard from "../ToolsCard/Toolscard";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function Tools() {
  const onButtonClick = () => { 
    document.getElementById("footer").scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => { 
    let proxy = { skew: 0 },
    skewSetter = gsap.quickSetter(".toolsCardAnimation", "skewY", "deg"),
    clamp = gsap.utils.clamp(-3, 3);  

    ScrollTrigger.create({
    onUpdate: (self) => {
        let skew = clamp(self.getVelocity() / -100); 
        if (Math.abs(skew) > Math.abs(proxy.skew)) {
        proxy.skew = skew;
        gsap.to(proxy, {skew: 0, duration: 0.8, ease: "power3", overwrite: true, onUpdate: () => skewSetter(proxy.skew)});
        }
    }
    }); 
    gsap.set(".toolsCardAnimation", {transformOrigin: "center center", force3D: true}); 
}, []);
  return (
    <section className="hmTools">
      <div className="container">
        <div className="toolsInner">
          <div className="toolsInnerWrap">
            <div className="pageTitle">
              <h2>Our Tools</h2>
              <p>We give you the power to change the world with your ideas.</p>
            </div>
            <div className="toolsCardRow">
              <ul>
                <li>
                  <ToolsCard className="toolsCardAnimation" title="Selenium" logoUrl={Images.seleniumLogo} />
                </li>
                <li>
                  <ToolsCard className="toolsCardAnimation" title="Test Rail" logoUrl={Images.testrailLogo} />
                </li>
                <li>
                  <ToolsCard className="toolsCardAnimation" title="Postman" logoUrl={Images.postmanLogo} />
                </li>
                <li>
                  <ToolsCard className="toolsCardAnimation" title="Jira" logoUrl={Images.JiraIcon} />
                </li>
                <li>
                <ToolsCard className="toolsCardAnimation" title="Github" logoUrl={Images.GithubIcon} />
                </li>
                <li>
                  <ToolsCard className="toolsCardAnimation" title="Android studio" logoUrl={Images.androidStudioLogo} />
                </li>
                <li>
                  <ToolsCard className="toolsCardAnimation" title="Bitbucket" logoUrl={Images.bitbucketLogo} />
                </li>
                <li>
                  <ToolsCard className="toolsCardAnimation" title="Visual Studio" logoUrl={Images.VisualStudioIcon} />
                </li>
                <li>
                  <ToolsCard className="toolsCardAnimation" title="Teams" logoUrl={Images.teamsLogo} />
                </li>
                <li>
                  <ToolsCard className="toolsCardAnimation" title="Pycharm" logoUrl={Images.pychamLogo} />
                </li>
                <li>
                  <ToolsCard className="toolsCardAnimation" title="Slack" logoUrl={Images.slackLogo} />
                </li>
                <li>
                  <ToolsCard className="toolsCardAnimation" title="Skype" logoUrl={Images.SkypeIcon} />
                </li>
                <li>
                  <ToolsCard className="toolsCardAnimation" title="Zoho" logoUrl={Images.zohoLogo} />
                </li>
                <li>
                  <ToolsCard className="toolsCardAnimation" title="Xcode" logoUrl={Images.xcodeLogo} />
                </li>
                <li>
                  <ToolsCard className="toolsCardAnimation" title="Invision" logoUrl={Images.invisionLogo} />
                </li> 
                <li>
                  <ToolsCard className="toolsCardAnimation" title="Figma" logoUrl={Images.FigmaIcon} />
                </li> 
                <li>
                  <ToolsCard className="toolsCardAnimation" title="illustrator" logoUrl={Images.AiLogo} />
                </li> 
              </ul>
            </div>
            <div className="toolsBtn">
              <button type="button" onClick={onButtonClick} className="btn btn-primary">
                Get in touch
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tools;
