import React from "react";
import { Link } from "react-router-dom";
import { Images } from "../../Config/images";
import '../Footer/Footer.scss';
import { useState } from "react";
import toast, { Toaster } from 'react-hot-toast';


function Footer() {
  const BASEURL = 'https://triodec.pythonanywhere.com/api/'

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [newsemail, setNews] = useState("");

  let handleSubmit = async (e) => {
    let body = {
      name: name,
      email: email,
      subject: subject,
      message: message
    }

    e.preventDefault();
    try {
      let res = await fetch(BASEURL + 'contactus/', {
        method: "POST",
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({input: body}),
      });

      if (res.status === 201) {
        toast.success('We have received your request, We will contact you shortly!',{
         minWidth: '250px',
          duration: 5000
        })

        e.preventDefault();
        e.target.reset();
      } else {
        toast.error("Something wen't wrong")
      }
    } catch (err) {
      console.log(err);
    }
  };

  let handleNewsSubmit = async (e) => {
    let body = {
      email: newsemail,
    }

    e.preventDefault();
    try {
      let res = await fetch(BASEURL + 'newsletter/', {
        method: "POST",
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({input: body}),
      });
    
      if (res.status === 201) {
        toast.success('Thank you for subscribing!',{
         minWidth: '250px',
          duration: 5000
        })

        e.preventDefault();
        e.target.reset();
      } else {
        toast.error("Something wen't wrong")
      }
    } catch (err) {
      console.log(err);
    }
  };

    return (
        <footer id="footer" className="footer">
            <div className="container">
                <div className="footerInner">
                    <div className="footerTop">
                        <div className="footerDetails">
                            <div className="footerLogo">
                                <Link to="/">
                                    <img src={Images.logo} alt="Logo" />
                                </Link>
                            </div>
                            <div className="footerSocial">
                                <ul>
                                    <li>
                                        <a href="/">
                                            <img src={Images.FacebookIcon} alt="Icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <img src={Images.TwitterIcon} alt="Icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <img src={Images.InstagramIcon} alt="Icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/triodec-solutions/mycompany/">
                                            <img src={Images.LinkedinIcon} alt="Icon" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footerEamil">
                                <p>Do you need help or support connect with</p>
                                <a href="mailto:solutions@triodec.com"><img src={Images.MailIcon} alt="Icon" /> solutions@triodec.com</a>
                            </div>
                            <div className="footerNewsletter">
                                <h2>Newsletter</h2>
                                <p>Subscribe to the free newsletter to receive information about current promotions and news</p>
                                  <form onSubmit={handleNewsSubmit}>
                                    <div className="form-check">
                                      <input type="email" className="form-control" id="" placeholder="Email" aria-describedby="" onChange={(e) => setNews(e.target.value)}
                                      required/>
                                      <button type="submit" className="btn btn-primary btn-send">
                                        <img src={Images.SendIcon} alt="Icon" />
                                      </button>
                                    </div>
                                  </form>
                            </div>
                            <div className="footerMenu">
                                <h6>Links</h6>
                                <ul>
                                    <li>
                                        <a href="/">About Us</a>
                                    </li>
                                    {/* <li>
                                        <a href="/">Career</a>
                                    </li> */}
                                    <li>
                                        <a href="/">Services</a>
                                    </li>
                                    {/* <li>
                                        <a href="/">Blogs</a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="footerForm">
                          <Toaster position="top-right" reverseOrder={false} gutter={24} />
                          <form onSubmit={handleSubmit}>
                            <div className="footerFormWrap">
                                <p className="footerFormText">If you have any questions feel free to contact us</p>
                                <div className="form-check">
                                    <input type="text" className="form-control" id="" placeholder="Your name" aria-describedby=""
                                    onChange={(e) => setName(e.target.value)} required/>
                                </div>
                                <div className="form-check">
                                    <input type="email" className="form-control" id="" placeholder="Email" aria-describedby=""
                                    onChange={(e) => setEmail(e.target.value)} required/>
                                </div>
                                <div className="form-check">
                                    <input type="text" className="form-control" id="" placeholder="Subject" aria-describedby=""
                                    onChange={(e) => setSubject(e.target.value)} required/>
                                </div>
                                <div className="form-check">
                                    <textarea className="form-control" placeholder="Message" aria-label="" 
                                    onChange={(e) => setMessage(e.target.value)} required></textarea>
                                </div>
                                <div className="form-check">
                                    <button type="submit" className="btn btn-contact">Contact Now</button>
                                </div>
                            </div>
                          </form>
                        </div>
                    </div>
                    <div className="footerCopyRight">
                        <p>Copyright 2022 by triodec. all right reserved</p>
                        <div className="footerCopyRightMenu">
                            <ul>
                               {/* <li>
                                    <a href="/">Privacy policy</a>
                                </li>
                                <li>
                                    <a href="/">Cookies</a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
