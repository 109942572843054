import React from "react";

function PerformanceTestingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="76"
      fill="none"
      viewBox="0 0 76 76"
    >
      <rect width="76" height="76" fill="#59D7B8" rx="20"></rect>
      <g
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        clipPath="url(#clip0_1108_7966)"
      >
        <path d="M46.333 56.75h6.25a3.103 3.103 0 003.083-3.083l.042-.042c0-1.708-1.438-3.125-3.125-3.125h-6.25v6.25z"></path>
        <path d="M46.333 44.25h5.208c1.75-.02 3.125 1.375 3.125 3.125a3.095 3.095 0 01-3.125 3.125h-5.208v-6.25zM21.333 31.75v-8.333c0-2.159 1.865-4.167 4.167-4.167 2.302 0 4.166 2.008 4.166 4.167v8.333M19.23 37.958a18.75 18.75 0 0018.75 18.75m18.75-18.75a18.75 18.75 0 00-18.75-18.75M29.666 27.583h-8.333"></path>
      </g>
      <defs>
        <clipPath id="clip0_1108_7966">
          <path
            fill="#fff"
            d="M0 0H50V50H0z"
            transform="translate(13 13)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default PerformanceTestingIcon;
