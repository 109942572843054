import React, { useEffect } from "react";
import { Images } from "../../Config/images";
import '../Triodec/Triodec.scss';
import TriodecTeg from "../TriodecTeg/TriodecTeg";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"; 
gsap.registerPlugin(ScrollTrigger); 

function Triodec() {
    useEffect(() => { 
        gsap.utils.toArray(".triodecInner").forEach((li: HTMLLIElement) => {
          gsap.fromTo(li, {
              opacity: 0,
              y: 80,
            },
            {
              ease: "none",
              opacity: 1,
              y: 0,
              scrollTrigger: {
                trigger: li,
                scrub: true,
                start: "top bottom",
                end: "+=300",
              },
            }
          );
        });
    
        setTimeout(() => {
          ScrollTrigger.refresh();
        }, 300);
    
      }, []);
    return (
        <section id="abousUsSec" className="hmTriodec">
            <div className="container">
                <div className="triodecInner">
                    <div className="triodecContent">
                        <h2>Why Choose Us?</h2>
                        <p>We will help you build a digital solution for your business. At Triodec, 
                            We consider our clients part of our team and involve them throughout the process. 
                            Triodec believes in delivering top-notch solutions for your business. Achieve 
                            well-planned milestones with us. Our goal is to provide customer centric solutions 
                            which helps our clients to grow more in their industry. Gain a better target 
                            audience market by taking your business online with us.</p>
                        <div className="triodecServices">
                            <ul>
                                <li>
                                    <TriodecTeg title="On time Delivery" bgColors="#00C1BD"/>
                                </li>
                                <li>
                                    <TriodecTeg title="Industry experts" bgColors="#22C7F4"/>
                                </li>
                                <li>
                                    <TriodecTeg title="Better price options" bgColors="#E063DB"/>
                                </li>
                                <li>
                                    <TriodecTeg title="Great customer experience" bgColors="#E9D753"/>
                                </li>
                                <li>
                                    <TriodecTeg title="Quality satisfaction" bgColors="#FF9D9D"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="triodecImg">
                        <img src={Images.TriodecImg} alt="Images"/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Triodec;