import React from "react";
import '../Technologies/Technologies.scss';

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { Images } from "../../Config/images";

function Technologies() {
    return (
        <section className="hmTechnologies">
            <div className="container">
                <div className="technologiesInner">
                    <div className="pageTitle">
                        <h2>Our Technologies</h2>
                        <p>We choose technology to suit your product, specific business goals, and stage of innovation. </p>
                    </div>
                    <div className="technologiesTabs">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="frontend">
                            <div className="technologiesTabsBtn">
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="frontend">Frontend</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="backend">Backend</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="database">Database</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="cloud">Cloud</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="quality">Quality Assurance</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="devops">Devops</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="technologiesTabsContent">
                                <Tab.Content>
                                    <Tab.Pane eventKey="frontend">
                                        <h4>Frontend</h4>
                                        <p>Front-end programming languages create the user interface and interactivity for 
                                            websites and web-based applications. These languages are responsible for the look, 
                                            feel, and behaviour of a website or application. 
                                        </p>
                                        <p>Look of the website, web app, mobile app, or software is dependent on the 
                                            front end we develop. User interface is the key element to attract users. It create the user interface (UI) that determines what each part of a site or application does and how it will look.
                                        </p>
                                        <ul className="logo-icons">
                                            <li><img src={Images.ReactIcon} alt="Icon" /></li>
                                            <li><img src={Images.AngularIcon} alt="Icon" /></li>
                                            <li><img src={Images.androidLogo} alt="Icon" /></li>
                                            <li><img src={Images.iosLogo} alt="Icon" /></li>
                                            <li><img src={Images.vueJsLogo} alt="Icon" /></li>
                                            <li><img src={Images.reactNativeLogo} alt="Icon" /></li>
                                            <li><img src={Images.flutterLogo} alt="Icon" /></li>
                                        </ul>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="backend">
                                        <h4>Backend</h4>
                                        <p>Backend Development is all about logic. A web developer working with backend technology takes care of the 
                                            server-side web application. In simple words, it’s the middleman that connects the front end and database 
                                            while completing a user request.  
                                        </p>
                                        <p>
                                        Good front-end technology always followed by a strong back-end code which helps user interface to run smoothly 
                                        without any error. This brings overall a good effect of application/website. 
                                        </p>
                                        <ul className="logo-icons">
                                            <li><img src={Images.pythonLogo} alt="Icon" /></li>
                                            <li><img src={Images.rubyonrailsLogo} alt="Icon" /></li>
                                            <li><img src={Images.djangoLogo} alt="Icon" /></li>
                                            <li><img src={Images.fastapiLogo} alt="Icon" /></li>
                                            <li><img src={Images.flaskLogo} alt="Icon" /></li>
                                            <li><img src={Images.nodeJsLogo} alt="Icon" /></li>
                                            <li><img src={Images.phpLogo} alt="Icon" /></li>
                                            <li><img src={Images.dotNetLogo} alt="Icon" /></li>
                                            <li><img src={Images.javaLogo} alt="Icon" /></li>
                                        </ul>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="database">
                                        <h4>Database</h4>
                                        <p>
                                        A database is a collection of data that has been organized for quick access, management, and updating. In computer databases, 
                                        information such as sales transactions, customer information, financial data, and product information are typically stored as 
                                        collections of data records or files.
                                        </p>
                                        <p>
                                        You can store, manage, and access any kind of data using a database. They gather data about people, places, or things. To enable 
                                        observation and analysis.
                                        </p>
                                        <ul className="logo-icons">
                                            <li><img src={Images.sqlServerLogo} alt="Icon" /></li>
                                            <li><img src={Images.postgreSqlLogo} alt="Icon" /></li>
                                            <li><img src={Images.elasticSearchLogo} alt="Icon" /></li>
                                            <li><img src={Images.mySqlLogo} alt="Icon" /></li>
                                            <li><img src={Images.mongoDBLogo} alt="Icon" /></li>
                                            <li><img src={Images.cassandraLogo} alt="Icon" /></li>
                                        </ul>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="cloud">
                                        <h4>Cloud</h4>
                                        <p>
                                        Cloud computing technology gives users access to storage, files, software, and servers 
                                        through their internet-connected devices: computers, smartphones, tablets, and wearables.
                                        </p>
                                        <p>
                                        Cloud computing providers store and process data in a location that’s separate from end users. Essentially, cloud computing means having the 
                                        ability to store and access data and programs over the internet instead of on a hard drive.
                                        </p>
                                        <ul className="logo-icons">
                                            <li><img src={Images.awsLogo} alt="Icon" /></li>
                                            <li><img src={Images.herokuLogo} alt="Icon" /></li>
                                            <li><img src={Images.digitalOceanLogo} alt="Icon" /></li>
                                            <li><img src={Images.azureLogo} alt="Icon" /></li>
                                            <li><img src={Images.hatchboxLogo} alt="Icon" /></li>
                                            <li><img src={Images.googleCloudLogo} alt="Icon" /></li>
                                        </ul>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="quality">
                                        <h4>Quality Assurance</h4>
                                        <p>
                                        Quality Assurance is the process of assessing and confirming that a software application or product performs as intended. Testing has 
                                        advantages such as bug prevention, lower development costs, and better performance.
                                        </p>
                                        <p>
                                        Software tests come in a variety of forms, each with unique goals and tactics: Validating base requirements is an important evaluation in any situation.
                                        </p>
                                        <ul className="logo-icons">
                                            <li><img src={Images.mochaLogo} alt="Icon" /></li>
                                            <li><img src={Images.jMeterLogo} alt="Icon" /></li>
                                            <li><img src={Images.soapuiLogo} alt="Icon" /></li>
                                            <li><img src={Images.ketlonStudioLogo} alt="Icon" /></li>
                                            <li><img src={Images.appiumLogo} alt="Icon" /></li>
                                            <li><img src={Images.sikuliLogo} alt="Icon" /></li>
                                        </ul>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="devops">
                                        <h4>DevOps</h4>
                                        <p>
                                        Software development and IT teams can automate and combine their processes with the use of a set of methods, tools, and cultural 
                                        principles called "DevOps."
                                        </p>
                                        <p>
                                        It places a strong emphasis on technology automation, cross-team communication, and team empowerment. Combining the terms 
                                        "development" and "operations," DevOps describes the process of fusing both fields into a single, continuous process. 
                                        </p>
                                        <ul className="logo-icons">
                                            <li><img src={Images.devopsCiCd} alt="Icon" /></li>
                                            <li><img src={Images.devopsJenkinsLogo} alt="Icon" /></li>
                                            <li><img src={Images.devopsGradleLogo} alt="Icon" /></li>
                                            <li><img src={Images.bitbucketLogo} alt="Icon" /></li>
                                            <li><img src={Images.circleciLogo} alt="Icon" /></li>
                                            <li><img src={Images.GithubIcon} alt="Icon" /></li>
                                            <li><img src={Images.awsCodepipelineLogo} alt="Icon" /></li>
                                            <li><img src={Images.hasuraLogo} alt="Icon" /></li>
                                        </ul>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Technologies;