import React from "react";

function WebEngineeringTwo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="76"
      fill="none"
      viewBox="0 0 76 76"
    >
      <rect width="76" height="76" fill="#FF9D9D" rx="20"></rect>
      <g
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        clipPath="url(#clip0_1108_7990)"
      >
        <path d="M46.333 19.25H29.667a4.167 4.167 0 00-4.167 4.167v29.166a4.167 4.167 0 004.167 4.167h16.666a4.167 4.167 0 004.167-4.167V23.417a4.167 4.167 0 00-4.167-4.167zM35.917 26h4.167M38 48.417v.02"></path>
      </g>
      <defs>
        <clipPath id="clip0_1108_7990">
          <path
            fill="#fff"
            d="M0 0H50V50H0z"
            transform="translate(13 13)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default WebEngineeringTwo;
