import React from "react";
import '../ToolsCard/Toolscard.scss';

function ToolsCard(props) {
    return (
        <div className={`toolsBox ${props.className}`}>
            <img src={props.logoUrl} alt="Icon" />
            <span>{props.title}</span>
        </div>
    );
};

export default ToolsCard;