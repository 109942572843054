import React from "react";

function UIUXDesignIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="76"
      fill="none"
      viewBox="0 0 76 76"
    >
      <rect width="76" height="76" fill="#A7CA83" rx="20"></rect>
      <g
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        clipPath="url(#clip0_1108_8001)"
      >
        <path d="M19.25 56.75h8.333l27.084-27.083a5.893 5.893 0 10-8.334-8.334L19.25 48.417v8.333zM43.208 24.458l8.333 8.334"></path>
        <path d="M38 29.667L27.583 19.25l-8.333 8.333L29.667 38M27.583 29.667l-3.125 3.125M46.333 38L56.75 48.417l-8.333 8.333L38 46.333M46.333 48.417l-3.125 3.125"></path>
      </g>
      <defs>
        <clipPath id="clip0_1108_8001">
          <path
            fill="#fff"
            d="M0 0H50V50H0z"
            transform="translate(13 13)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default UIUXDesignIcon;
