import React from "react";

function DigitalEngineeringIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="76"
      fill="none"
      viewBox="0 0 76 76"
    >
      <rect width="76" height="76" fill="#E8C159" rx="20"></rect>
      <g
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        clipPath="url(#clip0_1108_7959)"
      >
        <path d="M25.356 27.45l2.657 8.852m11.68 11.685l8.857 2.657M37.34 38.66l11.998-11.997M24.458 27.583a3.125 3.125 0 100-6.25 3.125 3.125 0 000 6.25zM51.542 27.583a3.125 3.125 0 100-6.25 3.125 3.125 0 000 6.25zM51.542 54.667a3.125 3.125 0 100-6.25 3.125 3.125 0 000 6.25zM30.708 54.667a9.375 9.375 0 100-18.75 9.375 9.375 0 100 18.75z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1108_7959">
          <path
            fill="#fff"
            d="M0 0H50V50H0z"
            transform="translate(13 13)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default DigitalEngineeringIcon;
