import React from "react";

function DomainConsultantIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="76"
      fill="none"
      viewBox="0 0 76 76"
    >
      <rect width="76" height="76" fill="#5974D7" rx="20"></rect>
      <g
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        clipPath="url(#clip0_1108_8042)"
      >
        <path d="M53.625 27.583A18.746 18.746 0 0038 19.25a18.732 18.732 0 00-15.592 8.333M36.959 19.25a35.394 35.394 0 00-3.805 8.333M39.042 19.25a35.391 35.391 0 013.808 8.342M53.625 48.417A18.746 18.746 0 0138 56.75a18.732 18.732 0 01-15.592-8.333M36.959 56.75a35.393 35.393 0 01-3.805-8.333M39.042 56.75a35.391 35.391 0 003.808-8.342M17.167 33.833l2.083 8.334 3.125-8.334 3.125 8.334 2.084-8.334M48.417 33.833l2.083 8.334 3.125-8.334 3.125 8.334 2.084-8.334M32.792 33.833l2.083 8.334L38 33.833l3.125 8.334 2.084-8.334"></path>
      </g>
      <defs>
        <clipPath id="clip0_1108_8042">
          <path
            fill="#fff"
            d="M0 0H50V50H0z"
            transform="translate(13 13)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default DomainConsultantIcon;
