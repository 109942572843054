import React from "react"; 
import './ApproachTag.scss'

function ApproachTag(props) {
    return(
        <label className={`approach-tag ${props.className}`}>
            <img className="icon" src={props.icon}  alt={props.label}/> {props.label}
        </label>
    )
}

export default ApproachTag;