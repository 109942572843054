import React, { useEffect, useState } from "react";
import { Images } from "../../Config/images";
import ApproachTag from "../ApproachTag/ApproachTag";
import "./OurApproachSec.scss";

function OurApproachSec() {
  const [circleValue, setcircleValue] = useState(0);
  const [planningCard, setPlanningCard] = useState('');
  const [designCard, setDesignCard] = useState('');
  const [developmentCard, setDevelopmentCard] = useState('');
  const [deploymentCard, setDeploymentCard] = useState('');
  const [testingCard, setTestingCard] = useState('');
  const [planningTwoCard, setPlanningTwoCard] = useState('');
  useEffect(() => {
      const s = setInterval(() => {
        circleValue < 100 && setcircleValue(c => c + 1);
        if (circleValue === 100) {
            setcircleValue(0);
            setPlanningCard('');
            setDesignCard('');
            setDevelopmentCard('');
            setDeploymentCard('');
            setTestingCard('');
            setPlanningTwoCard('');
         }
        if (circleValue === 1) {
          setPlanningCard('active');
         }
       if (circleValue === 16) {
          setDesignCard('active');
        }
       if (circleValue === 33) {
          setDevelopmentCard('active');
        }
       if (circleValue === 48) {
          setDeploymentCard('active');
       }
       if (circleValue === 63) {
          setTestingCard('active');
       }
       if (circleValue === 83) {
          setPlanningTwoCard('active');
       }
      }, 150); 
      return () => clearInterval(s);
  }, [circleValue]);


  return (
    <section className="our-approach-sec">
      <div className="container">
        <div className="our-approach-inner">
          <div className="pageTitle text-center">
            <h2>Our Approach </h2>
            <p>
            It starts with “What if?” And becomes, “Wouldn’t it be cool if?” Then it turns into, “Now that’s cool!” Ideas are ideas. They take shape in many forms and mediums. 
            </p>
          </div>
          <div className="progress-cricle-row">
          <div className="progress-circle">
          <ApproachTag icon={Images.apDesignTwoIcon} label="Discover" className={`planing-card ${planningCard}`}/>
            <ApproachTag icon={Images.apPlanningIcon} label="Plan" className={`design-card ${designCard}`}/>
            <ApproachTag icon={Images.apDesignIcon} label="Design" className={`development-card ${developmentCard}`}/>
            <ApproachTag icon={Images.apDevIcon} label="Development" className={`deployment-card ${deploymentCard}`}/>
            <ApproachTag icon={Images.apDeploymentIcon} label="Launch" className={`testing-card  ${testingCard}`}/>
            <ApproachTag icon={Images.apTestingIcon} label="Evolve" className={`planningtwo-card ${planningTwoCard}`}/> 

            <img className="ap-left-arrow" src={Images.approachLeftArrow} alt="left arrow"/>
            <svg className="border-circle">
              <circle cx="320" cy="320" r="315"></circle>
              <circle cx="320" cy="320" r="315" className="orange"
                style={{ strokeDashoffset: `calc(2000px - (2000px * ${circleValue} / 100)` }}
              ></circle>
              <circle cx="320" cy="320" r="315" className="white"></circle>
            </svg>
            <img className="ap-logo-cirrcle" src={Images.approachLogo} alt="left arrow"/>
            <img className="ap-right-arrow" src={Images.approachRightArrow} alt="left arrow"/>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurApproachSec;
