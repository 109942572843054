import React from "react";
import '../ModelsCard/ModelsCard.scss';


function ModelsCard(props) {
    return (
        <div  className={`modelsCrad ${props.className}`} style={{backgroundColor: props.bgColors}}>
            <div className="modelsCradIcon">
                <img src={props.dedicatedIcon} alt="Icon" />
            </div>
            <div className="modelsCradContent">
                <h4>{props.title}</h4>
                <p>{props.description}</p>
            </div>
        </div>
    );
};

export default ModelsCard;